<template>
  <div>
    <b-button
      size="sm"
      class="h-100"
      text="Button"
      variant="outline-primary"
      v-b-modal.ingredients-filter
      ><BIconReceipt class="mr-2" />Filtro Ingredienti</b-button
    >
    <b-modal
      centered
      size="xl"
      scrollable
      @ok="confirm"
      button-size="sm"
      id="ingredients-filter"
      title="Filtra per ingredienti"
    >
      <b-container>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <div v-if="availableIngredients.length">
                <b-form-checkbox
                  id="all"
                  name="all"
                  v-model="all"
                  value="true"
                  @change="toggleAll"
                >
                  {{ all ? "DESELEZIONA TUTTI" : "SELEZIONA TUTTI" }}
                </b-form-checkbox>
                <hr />
                <!-- <b-form-checkbox-group
                  stacked
                  class="mb-3"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  v-model="selectedIngredients"
                  :options="availableIngredients"
                ></b-form-checkbox-group> -->

                <b-form-checkbox-group
                  v-model="selectedIngredients"
                  class="mb-3"
                  disabled-field="notEnabled"
                >
                  <b-row>
                    <b-col
                      md="3"
                      cols="12"
                      :key="i.id"
                      v-for="i in availableIngredients"
                    >
                      <b-form-checkbox :value="i.id">{{
                        i.name
                      }}</b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-form-checkbox-group>
              </div>
              <NoData v-else message="Non trovo ingredienti!" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-cancel>Annulla</template>
      <template v-slot:modal-ok>Filtra</template>
    </b-modal>
  </div>
</template>

<script>
import NoData from "@/components/NoData.vue";
import { BIconReceipt } from "bootstrap-vue";

export default {
  name: "IngredientsFilter",
  components: { NoData, BIconReceipt },
  data: () => ({
    all: "",
    availableIngredients: [],
    selectedIngredients: [],
  }),
  methods: {
    confirm() {
      this.$emit("search", this.selectedIngredients.join(","));
    },
    toggleAll() {
      if (this.all) {
        this.selectedIngredients = this.availableIngredients.map((i) => i.id);
      } else this.selectedIngredients = [];
    },
    async getIngredients() {
      const response = await this.$http.get("/ingredients");
      this.availableIngredients = response.data.data;
    },
  },
  created() {
    this.getIngredients();
  },
};
</script>

<style>
</style>