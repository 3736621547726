<template>
  <b-card body-class="p-2">
    <div>
      <b-card-title
        class="h5 d-flex alignt-items-center justify-content-between"
      >
        <div>
          {{ pizza.name }}
          <span class="h6 mb-0 mr-2 text-primary"
            >&euro; {{ pizza.price }}</span
          >
        </div>
        <BIconPlusCircleFill
          class="mr-1 h4 text-primary clickable scale-hover"
          @click="addPizza"
        />
      </b-card-title>
      <b-card-sub-title
        v-text="pizza.white == true ? 'Base bianca' : 'Base rossa'"
      />
      <b-card-text>
        <em>{{ ingredientsList }}</em>
      </b-card-text>
    </div>
    <div class="mt-2 text-right"></div>
  </b-card>
</template>

<script>
import EventBus from "@/events/EventBus";
import { BIconPlusCircleFill } from "bootstrap-vue";
export default {
  name: "Pizza",
  components: {
    BIconPlusCircleFill,
  },
  props: ["pizza"],
  computed: {
    ingredientsList() {
      const ingredientsList = this.pizza.ingredients.reduce(
        (list, i) => list + i.name + ", ",
        ""
      );

      return ingredientsList.slice(0, -2);
    },
  },
  methods: {
    addPizza() {
      const { id, name, price } = this.pizza;
      EventBus.$emit("addedPizza", { id, name, price });
    },
  },
};
</script>

<style>
</style>