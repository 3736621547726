<template>
  <div id="app">
    <Loader v-if="isLoading" />
    <Alert />
    <Navbar />
    <b-container fluid>
      <Filters @filter="setFilters" />
      <hr />
      <section v-if="!isLoading" id="menu">
        <NoData v-if="!pizzas.length" message="Nessuna pizza trovata" />
        <b-tabs content-class=" pizza-tabs">
          <b-tab title="Pizze Classiche" active v-if="classicPizzas.length"
            ><PizzaList id="classic" :pizzas="classicPizzas"
          /></b-tab>
          <b-tab title="Pizze Speciali" v-if="specialPizzas.length"
            ><PizzaList
              id="special"
              :pizzas="specialPizzas"
              v-if="specialPizzas.length"
          /></b-tab>
        </b-tabs>
      </section>
    </b-container>
    <footer id="version" class="d-flex bg-white justify-content-between">
      <address class="mb-0 font">
        <em>Lanci Web & Silvia Rivella &copy;2021</em>
      </address>
      <span>Ver. 1.3.1</span>
    </footer>
  </div>
</template>

<script>
import Alert from "./components/Alert.vue";
import Loader from "./components/Loader.vue";
import Navbar from "./components/Navbar.vue";
import NoData from "./components/NoData.vue";
import Filters from "./components/Filters/Filters.vue";
import PizzaList from "./components/PizzaList.vue";

export default {
  name: "App",
  components: {
    Alert,
    Loader,
    Navbar,
    NoData,
    Filters,
    PizzaList,
  },
  data() {
    return { pizzas: [], filter: {}, isLoading: true };
  },
  computed: {
    specialPizzas() {
      return this.pizzas.filter(({ special }) => special == true);
    },
    classicPizzas() {
      return this.pizzas.filter(({ special }) => special == false);
    },
  },
  methods: {
    async getPizzas(params) {
      this.isLoading = true;
      const response = await this.$http.get("/pizzas", { params });
      this.pizzas = response.data.data;
      this.isLoading = false;
    },
    setFilters(filter) {
      this.getPizzas(filter);
    },
  },
  created() {
    this.getPizzas();
  },
};
</script>

<style lang="scss">
</style>
