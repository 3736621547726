<template>
  <div class="alert-container">
    <b-alert
      fade
      variant="success"
      :show="dismissCountDown"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <strong v-text="pizza" /> aggiunta al tuo ordine!
      <!--  <b-progress
        variant="success"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
  </div>
</template>

<script>
import EventBus from "@/events/EventBus";
export default {
  name: "Alert",
  data() {
    return {
      pizza: "",
      dismissSecs: 2,
      dismissCountDown: 0,
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  created() {
    EventBus.$on("addedPizza", ({ name }) => {
      this.pizza = name;
      this.showAlert();
    });
  },
  destroyed() {
    EventBus.$off("addedPizza");
  },
};
</script>

<style scoped lang="scss">
.alert-container {
  position: fixed;
  top: 20px;
  left: 0;
  z-index: 3;
  width: 100%;
}

.alert {
  width: 95%;
  margin: 0 auto;
}
</style>