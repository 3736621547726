<template>
  <b-input-group>
    <b-form-input
      v-model.trim="name"
      placeholder="Cerca pizza per nome"
    ></b-form-input>
    <b-input-group-append>
      <b-button
        size="sm"
        text="Button"
        @click="reset"
        variant="outline-secondary"
        ><BIconBackspaceFill
      /></b-button>
    </b-input-group-append>
    <b-input-group-append>
      <b-button size="sm" text="Button" variant="primary" @click="search"
        ><BIconSearch /></b-button></b-input-group-append
  ></b-input-group>
</template>

<script>
import { BIconSearch, BIconBackspaceFill } from "bootstrap-vue";
export default {
  name: "NameFilter",
  components: {
    BIconSearch,
    BIconBackspaceFill,
  },
  data: () => ({
    name: "",
  }),
  methods: {
    search() {
      if (this.name) {
        this.$emit("search", this.name);
      }
    },
    reset() {
      if (this.name) {
        this.name = "";
        this.$emit("search", this.name);
      }
    },
  },
};
</script>

<style>
</style>