<template>
  <b-table-simple striped hover :items="pizzas">
    <b-tbody>
      <b-tr v-for="pizza in pizzaTable" :key="pizza.selectionId">
        <b-td>
          <b-icon
            b-icon
            :icon="pizza.qty == 1 ? 'x-square-fill' : `dash-square-fill`"
            :class="pizza.qty == 1 ? 'text-primary' : 'text-secondary'"
            @click="$emit('removePizza', pizza.selectionId)"
          />
          <strong class="mx-1">{{ pizza.qty }}x</strong>
          <b-icon-plus-square-fill
            class="text-success"
            @click="$emit('addPizza', pizza.id)"
          />
        </b-td>
        <b-td>{{ pizza.name }}</b-td>
        <b-td class="text-right">&euro; {{ pizza.price }}</b-td>
      </b-tr>
      <b-tr
        ><b-td colspan="3" variant="primary" class="text-right"
          ><strong>TOTALE: &euro; {{ total }}</strong></b-td
        ></b-tr
      >
    </b-tbody>
  </b-table-simple>
</template>

<script>
import { BIconPlusSquareFill } from "bootstrap-vue";
import cleanArray from "@/helpers/cleanArray";
export default {
  name: "OrderTable",
  props: ["pizzas"],
  components: {
    BIconPlusSquareFill,
  },
  computed: {
    total() {
      const sum = this.pizzas.reduce(
        (sum, { price }) => sum + parseFloat(price),
        0
      );
      return sum.toFixed(2);
    },
    pizzaTable() {
      const pizzaTable = this.pizzas.reduce((table, p) => {
        // Search for duplicated pizza
        const alreadyPresent = cleanArray(table).find((el) => el.id === p.id);
        if (alreadyPresent) {
          //increase quantity
          alreadyPresent.qty++;
          const updatedPrice =
            parseFloat(alreadyPresent.price) + parseFloat(p.price);
          alreadyPresent.price = updatedPrice.toFixed(2);
          table[alreadyPresent.selectionId] = alreadyPresent;
        } else {
          table[p.selectionId] = { ...p, qty: 1 };
        }

        return table;
      }, []);
      return cleanArray(pizzaTable);
    },
  },
};
</script>