<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand href="#">Pizzeria Valdisogno</b-navbar-brand>
      <b-navbar-nav class="ml-auto d-flex">
        <div class="d-flex flex-row">
          <!-- <a href="tel:0184 998140" class="text-white mr-3"
            ><BIconTelephoneFill
          /></a> -->
          <Order />
        </div>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import Order from "@/components/Order.vue";
// import { BIconTelephoneFill } from "bootstrap-vue";
export default {
  name: "Navbar",
  components: { Order /* BIconTelephoneFill */ },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
