<template>
  <b-row class="my-3"
    ><b-col cols="12" class="mb-3"
      ><NameFilter @search="updateNameSearch" /></b-col
    ><b-col cols="6"><TypeFilter @search="updateTypeSearch" /> </b-col>
    <b-col cols="6" class="text-right"
      ><IngredientsFilter @search="updateIngredientsSearch" />
    </b-col>
    <!-- TODO handle type modal -->
  </b-row>
</template>

<script>
import NameFilter from "./NameFilter.vue";
import TypeFilter from "./TypeFilter.vue";
import IngredientsFilter from "./IngredientsFilter.vue";
export default {
  name: "Filters",
  components: {
    NameFilter,
    TypeFilter,
    IngredientsFilter,
  },
  data: () => ({
    filter: { name: "", white: "", special: "", ingredients: "" },
  }),
  methods: {
    updateNameSearch(name) {
      this.filter = { ...this.filter, name };
      this.$emit("filter", this.filter);
    },
    updateTypeSearch(types) {
      const { white, special } = types;
      this.filter = { ...this.filter, white, special };
      this.$emit("filter", this.filter);
    },
    updateIngredientsSearch(ingredients) {
      this.filter.ingredients = ingredients;
      this.$emit("filter", this.filter);
    },
  },
};
</script>

<style>
</style>