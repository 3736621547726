<template>
  <div class="position-relative" v-b-modal.order>
    <BIconBasket3Fill class="text-white" font-scale="2" />
    <span class="selected-pizzas" v-text="selectedPizzas.length" />

    <b-modal centered size="xl" scrollable id="order" title="Il tuo ordine">
      <OrderTable
        v-if="selectedPizzas.length"
        :pizzas="selectedPizzas"
        @addPizza="addPizza"
        @removePizza="removePizza"
      />
      <NoData v-else message="Nessuna pizza scelta" />
      <template #modal-footer>
        <div class="w-100 d-flex align-items-center justify-content-between">
          <b-button pill variant="secondary" @click="clearSelection"
            >Svuota <BIconTrash2Fill class="ml-2"
          /></b-button>
          <b-button pill variant="success" class="text-center ml-3 px-3"
            ><a href="tel:0184 998140" class="text-white"
              >Chiama <BIconTelephoneFill class="ml-2" /></a
          ></b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import EventBus from "@/events/EventBus";
import NoData from "@/components/NoData.vue";
import OrderTable from "@/components/OrderTable.vue";
import {
  BIconTrash2Fill,
  BIconBasket3Fill,
  BIconTelephoneFill,
} from "bootstrap-vue";

export default {
  name: "Order",
  components: {
    NoData,
    OrderTable,
    BIconTrash2Fill,
    BIconBasket3Fill,
    BIconTelephoneFill,
  },
  data: () => ({ selectedPizzas: [] }),
  methods: {
    clearSelection() {
      this.selectedPizzas = [];
      this.$bvModal.hide("order");
    },
    addPizza(id) {
      const pizza = this.selectedPizzas.find((p) => p.id === id);
      if (pizza) this.addToSelectedPizzas(pizza);
    },
    removePizza(selectionId) {
      this.selectedPizzas = this.selectedPizzas.filter(
        (p) => p.selectionId !== selectionId
      );
    },
    addToSelectedPizzas(pizza) {
      this.selectedPizzas.push({
        ...pizza,
        selectionId: this.selectedPizzas.length,
      });
    },
  },

  created() {
    EventBus.$on("addedPizza", (pizza) => {
      this.addToSelectedPizzas(pizza);
    });
  },
  destroyed() {
    EventBus.$off("addedPizza");
  },
};
</script>
