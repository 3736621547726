<template>
  <section class="my-3" :id="id">
    <b-row>
      <b-col sm="6" md="3" class="my-2" :key="pizza.id" v-for="pizza in pizzas">
        <Pizza :pizza="pizza" class="h-md-100" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Pizza from "@/components/Pizza.vue";

export default {
  name: "PizzaList",
  props: ["id", "title", "pizzas"],
  components: {
    Pizza,
  },
};
</script>

<style>
</style>