<template>
  <p id="loader">
    <BIconThreeDots aria-hidden="true" animation="cylon" font-scale="5" />
  </p>
</template>

<script>
import { BIconThreeDots } from "bootstrap-vue";
export default {
  components: {
    BIconThreeDots,
  },
  name: "Loader",
};
</script>

<style>
</style>