import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import '@/assets/scss/app.scss';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

axios.defaults.baseURL = 'https://marcolanci.it/valdisognoadmin/api';
// axios.defaults.baseURL = 'http://localhost:8000/api';
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');
