<template>
  <div
    class="
      my-3
      h-100
      d-flex
      flex-column
      align-items-center
      justify-content-center
    "
  >
    <BIconEmojiFrown font-scale="5" class="text-primary" />
    <h3 class="my-3 text-muted">{{ message }}</h3>
  </div>
</template>

<script>
import { BIconEmojiFrown } from "bootstrap-vue";
export default {
  name: "NoData",
  components: {
    BIconEmojiFrown,
  },
  props: ["message"],
};
</script>

<style>
</style>