<template>
  <div>
    <b-button
      size="sm"
      class="h-100"
      text="Button"
      variant="outline-primary"
      v-b-modal.type-filter
      ><BIconToggles class="mr-2" />Filtro Tipologia</b-button
    >
    <b-modal
      centered
      size="xl"
      scrollable
      @ok="sendFilters"
      button-size="sm"
      id="type-filter"
      title="Filtra per tipologia"
    >
      <b-container>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Base pizza"
              ><b-form-radio-group
                id="base"
                name="base"
                v-model="base"
                :options="baseOptions"
              ></b-form-radio-group
            ></b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Tipo pizze"
              ><b-form-radio-group
                id="type"
                name="type"
                v-model="type"
                :options="typeOptions"
              ></b-form-radio-group
            ></b-form-group>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-cancel>Annulla</template>
      <template v-slot:modal-ok>Filtra</template>
    </b-modal>
  </div>
</template>

<script>
import { BIconToggles } from "bootstrap-vue";

export default {
  name: "TypeFilter",
  components: {
    BIconToggles,
  },
  data: () => ({
    base: "",
    type: "",
    baseOptions: [
      { text: "Tutte", value: "" },
      { text: "Rosse", value: 0 },
      { text: "Bianche", value: 1 },
    ],
    typeOptions: [
      { text: "Tutte", value: "" },
      { text: "Classiche", value: 0 },
      { text: "Speciali", value: 1 },
    ],
  }),
  methods: {
    sendFilters() {
      this.$emit("search", { white: this.base, special: this.type });
    },
  },
};
</script>

<style>
</style>